/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Banner from "../components/banner"
import FeatureItem from "../components/featureItem"
import imgFeature_01 from "../images/graduate4.jpg"
import imgFeature_02 from "../images/donor2.jpg"

const FeaturesPage = () => (
  <Layout>
    <SEO title="Features" />
    <Section>
      <FeatureItem
        image={imgFeature_01}
        title="How It Works For Graduates"
        subtitle="Sign up for free and send out your graduation announcements in 3 easy steps:"
      >
        <ol sx={{ fontSize: "18px", textAlign: "left", m: 0, p: 0 }}>
          <li>Complete your profile</li>
          <li>Upload your contacts</li>
          <li>Send the pre-written announcement</li>
        </ol>
      </FeatureItem>
      <FeatureItem
        image={imgFeature_02}
        imageFirst
        title="How It Works For Donors"
        subtitle="Follow the link on your graduate’s announcement"
      >
        <ol sx={{ fontSize: "18px", textAlign: "left", m: 0, p: 0 }}>
          <li>Select a gift amount</li>
          <li>Attach a personal message (optional) and checkout</li>
        </ol>
      </FeatureItem>
    </Section>
    <Banner />
  </Layout>
)

export default FeaturesPage